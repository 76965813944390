import { toggleLanguage } from '@/helper'
import { useSavaSessionKey } from '@/hooks'
import { saveLog } from '@/services'
import { zeroFill, _format } from '@/utils/format'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { PopupNoti } from '../../common'
import useContentGlobal from '@/hooks/useContentGlobal'
import moment from 'moment'

type TProps = {
  dataOrderMovie: any
}

const AGE_RATING = ["T18", "T16"]

export const MovieInfoTotal = ({ dataOrderMovie }: TProps) => {
  const [total, setTotal] = useState(0) //Tổng giá trị đơn hàng tạm tính
  const [totalQty, setTotalQty] = useState(0) //Tổng giá trị đơn hàng tạm tính
  const [isOpen, setIsOpen] = useState(false)
  // const [isCheckView, setCheckView] = useState(false)
  const [note, setNote] = useState(false)
  const [timeCountDown, setTimeCountDown] = useState(0)
  const [checktime, setCheckTime] = useState(false)
  const { session_key } = useSavaSessionKey()

  const { getValueByKey } = useContentGlobal();

  const handelSaveLog = async (data: any) => {
    const formData = {
      input: data
    }
    const res = await saveLog.save(formData)
    return res
  }

  useEffect(() => {
    let totalPriceTicket = 0
    let totalPriceCombo = 0
    let qtyTicket = 0
    dataOrderMovie?.qtyTicket?.map((item: any) => {
      totalPriceTicket += parseInt(item?.price) * parseInt(item?.qty)
      qtyTicket += item?.qty
    })
    dataOrderMovie?.comboPick?.map((item: any) => {
      totalPriceCombo += parseInt(item?.price) * parseInt(item?.qty)
    })
    const total = totalPriceTicket + totalPriceCombo
    setTotal(total)
    setTotalQty(qtyTicket)
  }, [dataOrderMovie])
  const handleBooking = () => {
    localStorage.removeItem("movie")
    localStorage.removeItem("barCode")

    if (dataOrderMovie?.ordersSeat?.length < totalQty) {
      setIsOpen(true)
    } else {
      // const isCheck = dataOrderMovie?.qtyTicket?.find((item: any) => item?.ticket_id == "27")
      const newQtyTicket = dataOrderMovie?.qtyTicket?.filter((item: any) => item?.qty > 0)
      const dataEndcode = encodeURI(JSON.stringify({ ...dataOrderMovie, total: total, qtyTicket: newQtyTicket }))
      localStorage.setItem("movie", dataEndcode)
      // if (isCheck) {
        // setCheckView(true)
      // } else {
      //   router.push(`/checkout`)
      // }
      // setCheckView(true)
      router.push(`/checkout`)
    }
  }
  useEffect(() => {
    window.addEventListener('timerSeat', () => {
      setTimeCountDown(Number(sessionStorage.getItem('timerSeat')))
    })
  }, [])

  // Count down
  useEffect(() => {
    if (timeCountDown > 0) {
      const timer = setInterval(() => {
        let timeLeft = timeCountDown - new Date().getTime()
        const timerInterval = document.getElementById('timer')
        if (timerInterval) {
          let minutes = Math.floor(timeLeft / 1000 / 60)
          let seconds = Math.floor(timeLeft / 1000 % 60)
          if (minutes == 0 && seconds == 0) {
            clearInterval(timer)
            setCheckTime(true)
            timerInterval.innerHTML = `00:00 `
            const dataLog = {
              action: "time_out_booking",
              page_name: "Chi tiết phim",
              page_url: router.asPath,
              session_key: session_key,
              status: 'fail',
              error_message: "Time out booking",
              information: "Hết thời gian đặt vé",
              request_json: '',
            }
            handelSaveLog(dataLog)
            sessionStorage.removeItem('timerSeat')
          }
          timerInterval.innerHTML = `${zeroFill(minutes)}: ${zeroFill(seconds)} `
        }
      }, 1000)
      return () => clearInterval(timer)
    }
    return
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCountDown])

  if (!dataOrderMovie?.cinemaInterest) {
    return null
  }

  const handleClose = () => {
    setNote(false)
  }

  const isShowNote = () => {
    //thời lượng của phim
    const duration = moment.duration(dataOrderMovie?.movie?.time, "minutes")
    //thời gian bắt đầu chiếu (HH:mm)
    const startTime = dataOrderMovie?.cinemaInterest?.time?.split(":")
    //thời gian kết thúc phim  = ngày chiếu(giờ chiếu: phút chiếu) + thời lượng phim
    const endTime = moment(new Date(dataOrderMovie?.time?.date), "DD/MM/YYYY HH:mm")?.hours(startTime?.[0])?.minutes(startTime?.[1])?.add(duration)
    //thời gian cảnh báo
    const lateNightTime = moment(new Date(dataOrderMovie?.time?.date)).hour(22);
    if(!AGE_RATING?.includes(dataOrderMovie?.movie?.limitage_en?.toUpperCase()) && endTime?.isAfter(lateNightTime)){
      setNote(true)
    }else{
      handleBooking()
    }
  }

  return (
    <>
      <PopupNoti isOpen={isOpen} handleClose={() => setIsOpen(false)} contentTitle={getValueByKey(["Message", "Select_Seats"]) ?? "Vui lòng chọn đủ số ghế"} />
      <PopupNoti isOpen={checktime} handleClose={() => {
        setCheckTime(false)
        router.reload()
      }} contentTitle={getValueByKey(["Message", "Error", "Expired_Tickets_Hold"]) ?? "Đã hết thời gian giữ vé"} />

      <PopupNoti handleOk={handleBooking} extraButtonCancle isOpen={note} handleClose={handleClose} content={getValueByKey(["Message", "Late_Night_Warning"]) || "Đối với suất chiếu có thời gian kết thúc sau 22h và 23h: Rạp phim không được phép phục vụ khách hàng dưới 13 tuổi cho các suất chiếu kết thúc sau 22h và 16 tuổi cho suất chiếu kết thúc sau 23:00. Vui lòng đảm bảo quy định này, rạp sẽ không hoàn tiền nếu khách hàng vi phạm"} />

      {/* <PopupNoti isOpen={isCheckView} handleClose={() => {
        setCheckView(false)
        router.push(`/checkout`)
      }} contentTitle="LƯU Ý !" classCustom="--w7" content={getValueByKey(["Message", "Note_CCCD"]) ?? "Vui lòng mang theo <span class='c-second'>Căn cước công dân</span> hoặc thẻ <span class='c-second'>HSSV</span> để xác minh đối tượng mua vé. Phim có phân loại độ tuổi theo quy định, nếu vi phạm rạp sẽ từ chối vé của bạn.<br/>Vé đã đặt không hỗ trợ đổi, hoàn trả"} /> */}
      <div className="dt-bill bill-fixed bill-custom sticky">
        <div className="container">
          <div className="bill-wr" data-aos="fade-up">
            <div className="bill-left">
              <h4 className="name-combo">{toggleLanguage(dataOrderMovie?.movie, "name")}</h4>
              <ul className="list">
                <li className="item">
                  <span className="txt">{toggleLanguage(dataOrderMovie?.cinemaInterest, "theater_name")}</span>
                  {dataOrderMovie?.qtyTicket?.length > 0 &&
                    <>
                      <span className="dot">|</span>
                      <span className="txt">{dataOrderMovie?.qtyTicket?.filter((item: any) => item?.qty > 0)?.map((item: any) => `${item?.qty} ${item?.ticket_name}`)?.join(", ")}</span>
                    </>
                  }
                </li>
                {dataOrderMovie?.ordersSeat?.length > 0 && <li className="item"><span className="txt">Phòng chiếu :</span><span className="txt">{dataOrderMovie?.cinemaInterest?.room_name}</span><span className="dot">|</span><span className="txt">{dataOrderMovie?.ordersSeat?.map((item: any) => item?.name)?.join(", ")}</span><span className="dot">|</span><span className="dot">{dataOrderMovie?.cinemaInterest?.time}</span></li>}
                {dataOrderMovie?.comboPick && <li className="item"><span className="txt">{dataOrderMovie?.comboPick?.map((item: any) => `${item?.qty} ${item?.name}`)?.join(", ")}</span></li>}
              </ul>
            </div>
            <div className="bill-custom-right">
              <div className="bill-coundown">
                <p className="txt">
                {getValueByKey("Ticket_Holding_Time")}:
                </p>
                <div className="bill-time">
                  <span className="item" id='timer'>05:00</span>
                </div>
              </div>
              <div className="bill-right">
                <div className="price">
                  <span className="txt">{getValueByKey("Temporary_Price") ?? "Thời gian giữ vé"} </span>
                  <span className="num">{_format.getVND(total)}</span>
                </div>
                <button className={`btn btn--pri  ${totalQty > 0 && dataOrderMovie?.ordersSeat?.length == totalQty ? "opacity-100" : "opacity-40 pointer-events-none"}`} onClick={isShowNote}>{getValueByKey("Buy_Tickets") ?? "Đặt vé"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
