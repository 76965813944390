/* eslint-disable @next/next/no-img-element */
import { toggleLanguage } from '@/helper'
import useContentGlobal from '@/hooks/useContentGlobal'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { toast } from 'react-toastify'
import Fancybox from '../common/FancyboxVideo'

type TProps = {
  mv: TMovie
  child?: any
  idServer?: string
  idArea?: string
  btnText?: string
  isLazy?: boolean
  isComingSoon?: boolean
}

export const MovieItem: React.FC<TProps> = ({ mv, idServer, idArea, btnText, isLazy, isComingSoon }) => {
  const { getValueByKey } = useContentGlobal();

  const renderTypeOld = (text: string) => {
    if (text == "T18") {
      return "ADULT"
    }
    if (text == "T13" || text == "T16") {
      return "TEEN"
    }
    if (text == "K" || text == "P") {
      return "KID"
    }
    return ""
  }

  const renderTextBtn = () => {
    if (btnText) return btnText;
    return getValueByKey("Buy_Tickets") ?? "Đặt vé"
  }

  return (
    <div className="web-movie-box">
      <Link legacyBehavior href={`/movie/${mv?.id}${idServer && idArea ? `?id=${idArea}&id_sv=${idServer}` : ""}`}>
        <a className="image" href={`/movie/${mv?.id}`}>
          <Image src={mv.image ?? ""} alt="" quality={50} width={280} height={423} sizes="(max-width: 768px) 135px, 280px"
            className="!top-1/2 !left-1/2" loading={isLazy ? "lazy" : "eager"} />
          <div className="content" >
            <div className="inner">
              <p className="name" suppressHydrationWarning>{toggleLanguage(mv, "name") || ""}</p>
              <div className="info-item">
                <Image width={24} height={24} src="/assets/images/icon-tag.svg" alt="" className='ic' />
                <span className="txt" suppressHydrationWarning>{toggleLanguage(mv, "type_name") || ""}</span>
              </div>
              {mv.time &&
                <div className="info-item">
                  <Image className='ic' width={24} height={24} loading='lazy' src="/assets/images/icon-clock.svg" alt="" />
                  <span className="txt">{mv.time + "'"}</span>
                </div>
              }
              <div className="info-item">

                <i className="fa-regular fa-earth-americas fa-lg ic" style={{ color: "#ede12d", fontSize: '1.55rem' }}></i>

                <span className="txt" suppressHydrationWarning>{toggleLanguage(mv, "country_name") || ""}</span>
              </div>
              <div className="info-item">
                <Image className='ic' width={24} height={24} loading='lazy' src="/assets/images/subtitle.svg" alt="" />
                <span className="txt" suppressHydrationWarning>{toggleLanguage(mv, "language") || ""}</span>
              </div>
            </div>
          </div>
          <div className="attach">
            <div className={`type-movie ${mv?.formats_name_vn != "2D" ? "label-3d" : ""}`}> <span className="txt" suppressHydrationWarning>{toggleLanguage(mv, "formats_name") || ""}</span></div>
            <div className="age">
              <span className="num" suppressHydrationWarning>{toggleLanguage(mv, "limitage") || ""} </span>
              <span className="txt" suppressHydrationWarning>{renderTypeOld(toggleLanguage(mv, "limitage"))}</span>
            </div>
          </div>
        </a>
      </Link>
      <div className={`info ${!!isComingSoon ? "extra-premiere" : ""}`}>
        {!!isComingSoon && <div className='date-premiere'>{getValueByKey(["Movie_Detail", "Premiere"])}: {moment(new Date(mv?.release_date))?.format("DD/MM/YYYY")}</div>}
        <Link legacyBehavior={true} href={`/movie/${mv?.id}${idServer && idArea ? `?id=${idArea}&id_sv=${idServer}` : ""}`}>
          <a className="name" suppressHydrationWarning aria-label={toggleLanguage(mv, "name")}>{toggleLanguage(mv, "name") || ""}</a>
        </Link>
        <div className="info-action !mt-auto">
          {
            mv?.trailer !== "" ?
              <Fancybox>
                <a className="video --cs-poiter" data-fancybox href={`https://youtu.be/${mv?.trailer}`}>
                  <Image className='ic' width={23} height={23} loading={isLazy ? "lazy" : "eager"} src="/assets/images/icon-play-vid.svg" alt="" />
                  <span className="txt" suppressHydrationWarning>{getValueByKey("Watch_Trailer")}</span>
                </a>
              </Fancybox>
              :
              <div
                className="video --cs-poiter"
                onClick={() => {
                  toast.warning(getValueByKey(["Message", "Error", "Empty_Trailer"]) ?? "Xin lỗi chưa có trailer")
                }}
              >
                <Image className='ic' width={23} height={23} loading={isLazy ? "lazy" : "eager"} src="/assets/images/icon-play-vid.svg" alt="" />
                <span className="txt" suppressHydrationWarning>{getValueByKey("Watch_Trailer")}</span>
              </div>
          }

          <Link legacyBehavior href={`/movie/${mv?.id}${idServer && idArea ? `?id=${idArea}&id_sv=${idServer}` : ""}`}>
            <a href={`/movie/${mv?.id}`} className="btn btn--pri">{renderTextBtn()}</a>
          </Link>
        </div>
      </div>
    </div>
  )
}