/* eslint-disable @next/next/no-img-element */
import { convertDate, toggleLanguage } from '@/helper'
import useContentGlobal from '@/hooks/useContentGlobal'
import $ from 'jquery'
import moment from 'moment'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
 
const Fancybox = dynamic(() => import('../../common/FancyboxVideo'), {
  ssr:false
})

type TProps = {
  movie: TMovie
}

export const MovieDetailInfo: React.FC<TProps> = ({ movie }) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const [height, setHeight] = useState(0)
  const [lineHeight, setLineHeight] = useState(0)
  const [heightMobile, setHeightMobile] = useState(0)
  const [lineHeightMobile, setLineHeightMobile] = useState(0)

  const { getValueByKey } = useContentGlobal();

  const ref = useRef(null)

  useEffect(() => {
    //@ts-ignore
    setHeight(ref.current?.offsetHeight)
    setLineHeight(parseInt($(ref.current || {}).css("line-height"), 10))
    //@ts-ignore
    setHeightMobile(refMobile.current?.offsetHeight)
    setLineHeightMobile(parseInt($(refMobile.current || {}).css("line-height"), 10))
  }, [])
  const refMobile = useRef(null)

  const renderTypeOld = (text: string) => {
    if (text == "T18" || text == "T16") {
      return "ADULT"
    }
    if (text == "T13" || text == "K" || text == "P") {
      return "KID"
    }
    return ""
  }

  return (
    <section className="sec-detail">
      <div className="detail ht">
        <div className="container">
          <div className="detail-wr">
            <div className="detail-row row" data-aos="fade-up">
              <div className="detail-left col col-5">
                <div className="web-movie-box">
                  <div className="image"> <Image src={`${movie?.image ?? ""}`} alt={toggleLanguage(movie, "name")} fill className='!top-1/2 !left-1/2' loading='eager' priority/>
                    <div className="attach">
                      <div className={`type-movie ${movie?.formats_name_vn != "2D" ? "label-3d": "" }`}> <span className="txt">{toggleLanguage(movie, "formats_name")}</span></div>
                      <div className="age">
                        <span className="num">{toggleLanguage(movie, "limitage")} </span>
                        <span className="txt">{renderTypeOld(toggleLanguage(movie, "limitage"))}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-right col col-7">
                <div className="detail-ct">
                  <div className="detail-ct-h">
                    <h1 className="heading">{toggleLanguage(movie, "name")}</h1>
                    <ul className="info-detail">
                      <li className="info-item"> <span className="ic"> <Image src="/assets/images/icon-tag.svg" alt="" fill/></span><span className="txt">{toggleLanguage(movie, "type_name")}</span></li>
                      {movie?.time && <li className="info-item"> <span className="ic"> <Image src="/assets/images/icon-clock.svg" alt="" fill/></span><span className="txt">{movie?.time + "'"}</span></li>}
                      <li className="info-item"> 
                        <span className="ic"> 
                          <i className="fa-regular fa-earth-americas fa-lg " style={{ color: "#ede12d",fontSize:'2.1rem'}}></i>
                        </span>
                        <span className="txt">{toggleLanguage(movie, "country_name")}</span>
                      </li>
                      <li className="info-item">
                        <span className="ic">
                          <Image loading='lazy' sizes="(max-width: 768px) 50vw, 100vw" src="/assets/images/subtitle.svg" alt="" fill/>
                        </span>
                        <span className="txt">{toggleLanguage(movie, "language")}</span>
                      </li>
                      <li className="info-item limit-age">
                        <span className="ic">
                          <i className="fa-regular fa-user-check" style={{ color: "#ede12d", }} />
                        </span>
                        <span className="txt">{toggleLanguage(movie, "limitage")}: {movie && (getValueByKey(toggleLanguage(movie, "limitage")?.toUpperCase()) ?? "")}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="detail-ct-bd">
                    <h3 className="tt sub-tittle">{getValueByKey(["Movie_Detail", "Describe"])}</h3>
                    <ul>
                      {movie?.director &&
                        <li>
                          {getValueByKey(["Movie_Detail", "Manager"])}: {movie.director}
                        </li>
                      }
                      {movie?.actor &&
                        <li>
                          {getValueByKey(["Movie_Detail", "Performers"])}: {movie.actor}
                        </li>
                      }
                      {movie?.release_date &&
                        <li>
                          {getValueByKey(["Movie_Detail", "Premiere"])}: {toggleLanguage(convertDate(moment(new Date(movie.release_date)).format("DD/MM/YYYY")), "label")}, {moment(new Date(movie.release_date)).format("DD/MM/YYYY")}
                        </li>
                      }
                    </ul>
                  </div>
                  <div className="detail-ct-bd">
                    <h3 className="tt sub-tittle">{getValueByKey(["Movie_Detail", "Movie_Content"])}</h3>
                    <div className="ct">
                      <input id="drop-text-tab" hidden type="checkbox" />
                      <div className="dt" ref={ref}>
                        <p ref={ref} className={`txt ${isShowMore ? "line-clamp-none" : "line-clamp-6"} `}>{toggleLanguage(movie, "brief")}</p>
                      </div>
                      {height / lineHeight > 6 && (isShowMore ? (
                        <span className="text-xl cursor-pointer underline hover:text-[#f3ea28] duration-200" onClick={() => setIsShowMore(false)}>{getValueByKey("Collapse")}</span>
                      ) : (
                        <span className="text-xl cursor-pointer underline hover:text-[#f3ea28] duration-200" onClick={() => setIsShowMore(true)} >{getValueByKey("See_More")} </span>
                      ))}
                    </div>
                  </div>
                  <div className="detail-ct-ft">
                    {
                      movie?.trailer !== "" ?
                        (
                          <Fancybox classNameContainer='wrap-btn-trailer'>
                            <a href={`https://youtu.be/${movie?.trailer}`} data-fancybox className="video">
                              <span className="ic">
                                <Image width={40} height={40} src="/assets/images/ic-play-circle-red.svg" alt="" />
                              </span> 
                              <span className="txt">{getValueByKey("Watch_Trailer")}</span>
                            </a>
                          </Fancybox>
                        )
                        :
                        (
                          <div className="video --cs-poiter"
                            onClick={() => {
                              toast.warning(getValueByKey(["Message", "Error", "Empty_Trailer"]) ?? "Xin lỗi chưa có trailer")
                            }}
                          >
                            <span className="ic">
                              <img src="/assets/images/ic-play-circle-red.svg" alt="" />
                            </span>
                            <span className="txt">{getValueByKey("Watch_Trailer")}</span>
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-ct-bd dt-mobile">
              <h3 className="tt sub-tittle">{getValueByKey(["Movie_Detail", "Describe"])}</h3>
              <div className="ct">
                <div className="dt !max-h-fit">
                  {movie?.director && <p className="txt ">{getValueByKey(["Movie_Detail", "Manager"])}: {movie.director}</p>}
                  {movie?.actor && <p className="txt ">{getValueByKey(["Movie_Detail", "Performers"])}: {movie.actor}</p>}
                  {movie?.release_date && <p className="txt mb-[4px]">{getValueByKey(["Movie_Detail", "Premiere"])}: {moment(new Date(movie.release_date)).format("DD/MM/YYYY")}</p>}
                </div>
              </div>
              <h3 className="tt sub-tittle">{getValueByKey(["Movie_Detail", "Movie_Content"])}</h3>
              <div className="ct">
                <div className="dt">
                  <p ref={refMobile} className={`txt ${isShowMore ? "line-clamp-none" : "line-clamp-2"} `}>{toggleLanguage(movie, "brief")}</p>
                </div>
                <div className="item-detail">
                  {heightMobile / lineHeightMobile > 2 && (isShowMore ? (
                    <span className="text-sm cursor-pointer underline hover:text-[#f3ea28] duration-200" onClick={() => setIsShowMore(false)}>{getValueByKey("Collapse")}</span>
                  ) : (
                    <span className="text-sm cursor-pointer underline hover:text-[#f3ea28] duration-200" onClick={() => setIsShowMore(true)} >{getValueByKey("See_More")} </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
