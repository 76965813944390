/* eslint-disable @next/next/no-img-element */
import { convertDate, toggleLanguage } from "@/helper"
import useContentGlobal from "@/hooks/useContentGlobal"
import useSavaSessionKey from "@/hooks/useSavaSessionKey"
import { sendLog } from "@/services/sendLog"
import { collapseActionRenderByOne, collapseInitActiveAll } from '@/utils/CollapseConfig'
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

type Props = {
  movie: any
  theater: any
  isNowShowing?: boolean
}



export const MovieBox = ({ movie, theater, isNowShowing }: Props) => {
  const [showTime, setShowTime] = useState<any>()
  const [firstTime, setFirstTime] = useState<any>(null)
  const { session_key } = useSavaSessionKey();
  const router = useRouter()

  const { getValueByKey } = useContentGlobal();

  // gọi suất chiếu 
  const fetchDataShowTime = async (id_Movie?: string, id_Area?: string, id_Server?: string, date?: string, id_MovieTheater?: string) => {
    const res = await fetch(`/api/showTime?id_Movie=${id_Movie}&id_Area=${id_Area}&id_Server=${id_Server}&date=${date}&id_MovieTheater=${id_MovieTheater}`)
      .then((res) => res.json())
      .catch((err) => {
        sendLog({
          action: "fetch_data_showtime",
          page_name: "MovieBox",
          page_url: router.asPath,
          session_key: session_key,
          status: "fail",
          error_message: JSON.stringify(err),
          information: "Fetch data showtime fail",
          request_json: JSON.stringify({
            id_Movie: id_Movie,
            id_Area: id_Area,
            id_Server: id_Server,
            date: date,
            id_MovieTheater: id_MovieTheater
          })
        })
      })
    // setShowTime(res?.data?.[0]?.schedule)
    // sắp xếp theo ngày và lọc ra những suất chiếu còn hạn
    const newShowTime = res?.data?.[0]?.schedule?.sort((a: any, b: any) => new Date(convertDate(a?.date)?.date || 0)?.getTime() - new Date(convertDate(b?.date)?.date || 0)?.getTime())?.map((item: any) => {
      const currentDay = item?.date
      const listTime = (item?.times?.filter((itmTime: any) => {
        const presentTime = moment().unix()
        const movieTime = moment(`${currentDay} ${itmTime?.time}`, "DD/MM/YYYY HH:mm").unix()
        if (presentTime < movieTime) {
          return itmTime
        }
      }))
      return { ...item, times: listTime }
    }).filter((value: any) => value?.times?.length > 0)

    setShowTime(newShowTime)
    setFirstTime(newShowTime?.[0]?.times?.[0]?.showtime_id || newShowTime?.[1]?.times?.[0]?.showtime_id)
    return res
  }
  const date = new Date().getDate()?.toString()
  useEffect(() => {
    fetchDataShowTime(movie?.id, theater?.area_id, theater?.id_server, date, theater?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, movie?.id, theater?.area_id, theater?.id, theater?.id_server])

  useEffect(() => {
    collapseInitActiveAll();
    collapseActionRenderByOne();
  }, [showTime])
  if (isNowShowing && (!showTime || showTime?.length == 0)) return null
  return (
    <div className="movies-item col col-6">
      <div className="movies-wr">
        <div className="movies-img">
          <Link href={`/movie/${movie?.id}${theater ? `?id=${theater?.area_id}&id_sv=${theater?.id_server}${firstTime ? '&show_time=' + firstTime : ''}${showTime?.[0]?.date ? '&date=' + showTime?.[0]?.date : ''}` : ""}`} className="inner">
            <img src={movie?.image} alt="" />
          </Link>
          <div className="movies-type is-mobile margin-top movies-type-custom">
            <ul>
              <li>
                <i className="fa-regular fa-user-check"></i>
                <span className="txt">{toggleLanguage(movie, "limitage")}: {getValueByKey(toggleLanguage(movie, "limitage")?.toUpperCase()) ?? ""}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="movies-content">
          <h3 className="movies-name">
            <Link href={`/movie/${movie?.id}${theater ? `?id=${theater?.area_id}&id_sv=${theater?.id_server}${firstTime ? '&show_time=' + firstTime : ''}${showTime?.[0]?.date ? '&date=' + showTime?.[0]?.date : ''}` : ""}`}>{toggleLanguage(movie, "name")}</Link>
          </h3>
          <div className="movies-type is-desktop">
            <ul>
              <li>
                <img src="/assets/images/icon-tag.svg" alt="" />
                <span className="txt">{toggleLanguage(movie, 'type_name')}</span>
              </li>
              <li>
                <img src="/assets/images/icon-clock.svg" alt="" />
                <span className="txt">{movie?.time}</span>
              </li>
              <li>
                <i className="fa-regular fa-earth-americas"></i>
                <span className="txt">{toggleLanguage(movie, 'country_name')}</span>
              </li>
              <li>
                <img src="/assets/images/subtitle.svg" alt="" />
                <span className="txt">{toggleLanguage(movie, "language")}</span>
              </li>
              <li>
                <i className="fa-regular fa-user-check"></i>
                <span className="txt">{toggleLanguage(movie, "limitage")}: {getValueByKey(toggleLanguage(movie, "limitage")?.toUpperCase()) ?? ""}</span>
              </li>
            </ul>
          </div>
          {
            showTime?.length > 0 ?
              <div className="movies-rp collapseBlockJS">
                {
                  showTime?.slice(0, 2).map((item: any, index: number) => {
                    const listTimeStandard = item?.times?.filter((itm: any) => itm?.room_type_name_en === 'Standard')
                    const listTimeDelux = item?.times?.filter((itm: any) => itm?.room_type_name_en === "Deluxe")

                    return (
                      <div className="movies-rp-block collapseItem" key={index}>
                        {item?.times?.length > 0 &&
                          <div className="movies-rp-day collapseHead">
                            <span className="txt">{toggleLanguage(convertDate(item?.date), "label")}, {item?.date}</span>
                            <i className="far fa-angle-down"></i>
                          </div>}
                        <div className="movies-rp-body collapseBody">
                          {
                            listTimeStandard.length > 0 &&
                            <div className="movies-rp-item">

                              {listTimeStandard.length > 0 && (
                                <p className="movies-rp-title">
                                  Standard
                                </p>
                              )}
                              <div className="movies-time">
                                {
                                  listTimeStandard?.length > 0 &&
                                  <div className="movies-time-slider">
                                    <div className="swiper-container">
                                      <div className="swiper rows">
                                        <div className="swiper-wrapper">
                                          {listTimeStandard?.map((showTime: any, index: number) => {
                                            return (
                                              <div className="swiper-slide col" key={index}>
                                                <Link href={`/movie/${movie?.id}${theater ? `?id=${theater?.area_id}&id_sv=${theater?.id_server}${showTime?.showtime_id ? '&show_time=' + showTime?.showtime_id : ''}` : ""}${item?.date ? '&date=' + item?.date : ''}`} className={`movies-time-item ${firstTime === showTime?.showtime_id ? 'active' : ''}`}>
                                                  {showTime?.time}
                                                </Link>
                                              </div>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          }
                          {
                            listTimeDelux?.length > 0 &&
                            <div className="movies-rp-item">
                              {listTimeDelux?.length > 0 && (
                                <p className="movies-rp-title">
                                  Deluxe
                                </p>
                              )}
                              <div className="movies-time">
                                <div className="movies-time-slider">
                                  <div className="swiper-container">
                                    <div className="swiper rows">
                                      <div className="swiper-wrapper">
                                        {listTimeDelux?.map((showTime: any, index: number) => {
                                          return (
                                            <div className="swiper-slide col" key={index}>
                                              <Link href={`/movie/${movie?.id}${theater ? `?id=${theater?.area_id}&id_sv=${theater?.id_server}${showTime?.showtime_id ? '&show_time=' + showTime?.showtime_id : ''}` : ""}${item?.date ? '&date=' + item?.date : ''}`} className={`movies-time-item ${firstTime === showTime?.showtime_id ? 'active' : ''}`}>
                                                {showTime?.time}
                                              </Link>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              :
              <div className="movies-rp-noti">
                <img src="/assets/images/movie-updating.png" alt="" />
                {getValueByKey("Screening_Empty")}
              </div>
          }
          <Link href={`/movie/${movie?.id}${theater ? `?id=${theater?.area_id}&id_sv=${theater?.id_server}` : ""}`} className="btn-see-more">{getValueByKey("See_More_Showtimes") || "Xem thêm lịch chiếu"}</Link>
        </div>
      </div>
    </div>
  )
}