/* eslint-disable @next/next/no-img-element */
import useContentGlobal from '@/hooks/useContentGlobal';
import { swiperConfig } from '@/utils/SwiperConfig';
import Link from 'next/link';
import { useEffect } from 'react';
import dynamic from 'next/dynamic';

// Dynamic import với Server-Side Rendering (SSR) tắt
const MovieItem = dynamic(() => import('./MovieItem').then(component => component.MovieItem));
const MovieItemMobile = dynamic(() => import('./MovieItemMobile').then(component => component.MovieItemMobile));


type Props = {
  movies: any,
  isShowMore?: boolean,
  title?: string
  isGrid?: boolean,
  isShowMoreUrl?: string,
  noTitle?: boolean
  customWrap?: string
  isComingSoon?: boolean
  isLazy?: boolean
  classNames?: string
  maxItems?: number
  mobileItem?: boolean
}

export const MovieSection = ({ maxItems, mobileItem, ...props }: Props) => {
  let limit = maxItems || props?.movies?.length
  const { getValueByKey } = useContentGlobal();
  const isUpcomingMovie = props?.title === "Phim sắp chiếu" || props?.title === getValueByKey("Coming_Soon");

  useEffect(() => {
    swiperConfig(".web-movie-list", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      slidesPerView: "auto",
      slidesPerGroup: 4,
      autoPlay: false,
      breakpoints: {
        0: {
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerGroup: 4,
        }
      },
    }, "bullets");
  }, [props.movies])
  return (
    <section className={`web-movie-slide ${props.classNames || ''}`}>
      <div className={`movie-showing ht ${props.noTitle ? "remove-padding" : ""} ${props.customWrap ?? ""}`}>
        <div className={`container ${!props.noTitle ? "keep-stable" : ""}`}>
          {!props.noTitle && <div className="web-movie-head sec-heading" data-aos="fade-up">
            <div className="heading">
              <h1>{props?.title || "Phim đang chiếu"}</h1>
            </div>
          </div>}
          <div className="web-movie-content" data-aos="fade-up">
            {
              !props.isGrid ? (
                <>
                  <div className='web-movie-list'>
                    <div className="swiper-container">
                      <div className="swiper rows">
                        <div className="swiper-wrapper">
                          {
                            props?.movies?.slice(0, limit)?.map((mv: any, mvIndex: any) => {
                              return (
                                <div className="swiper-slide web-movie-item cols" key={mvIndex}>
                                  {mobileItem ? <MovieItemMobile isComingSoon={!!props?.isComingSoon} mv={mv} child={props} btnText={isUpcomingMovie ? (getValueByKey("Find_Out_More") ?? "Tìm hiều thêm") : ""} isLazy={false} /> : <MovieItem isComingSoon={!!props?.isComingSoon} mv={mv} child={props} btnText={isUpcomingMovie ? (getValueByKey("Find_Out_More") ?? "Tìm hiều thêm") : ""} isLazy={false} />}
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className="web-movie-pagination swiper-pagination min-h-[16px]"></div>
                    <div className="arrow-btn-prev swiper-prev"><i className="far fa-chevron-left"></i></div>
                    <div className="arrow-btn-next swiper-next"><i className="far fa-chevron-right"></i></div>
                  </div>
                </>
              )
                : (
                  <div className="mv-grid-list row">
                    {
                      props?.movies?.slice(0, maxItems)?.map((mv: any, mvIndex: any) => {
                        return (
                          <div className="mv-grid-item col col-3" key={mvIndex}>
                            {mobileItem ? <MovieItemMobile isComingSoon={!!props?.isComingSoon} mv={mv} child={props} btnText={isUpcomingMovie ? (getValueByKey("Find_Out_More") ?? "Tìm hiểu thêm") : ""} isLazy={false} /> : <MovieItem isComingSoon={!!props?.isComingSoon} mv={mv} child={props} btnText={isUpcomingMovie ? (getValueByKey("Find_Out_More") ?? "Tìm hiểu thêm") : ""} isLazy={false} />}

                          </div>
                        )
                      })
                    }
                  </div>
                )
            }
          </div>
          {
            props.isShowMore &&
            <Link className="btn-more" data-aos="fade-up" legacyBehavior href={`${props.isShowMoreUrl}`}>
              <a className="btn btn--outline --wf --mw --m-mid" href={props.isShowMoreUrl}>{getValueByKey("See_More") ?? "Xem thêm"}</a>
            </Link>
          }
        </div>
      </div>
    </section>
  )
}