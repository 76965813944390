

type Props = {
  handlePlus: any,
  handleMinus: any,
  quantity: number
}

export const CountQuantity = (props: Props) => {
  return (
    <div className="count">
      <div className="count-btn count-minus" onClick={props.handleMinus}><i className="fas fa-minus icon"></i></div>
      <p className="count-number">{props.quantity}</p>
      <div className="count-btn count-plus" onClick={props.handlePlus}><i className="fas fa-plus icon"></i></div>
    </div>
  )
}