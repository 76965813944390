/**
 *
 * @param a list cinema
 * @param setValue set state Fn
 * @param setValue set error Fn
 * @returns
 */
export const SortCinema = (a: any[], setValue: Function, setError?: Function) => {
	const options = {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0
	}
	function tinhKhoangCach(x1: number, y1: number, x2: number, y2: number): number {
		let khoangCach = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
		return khoangCach
	}

	const sortCinemasByDistance = (cinemas: any[], currentLocation: { latitude: number; longitude: number }): any[] => {
		// Tính khoảng cách từ vị trí hiện tại và thêm vào thuộc tính kc của mỗi địa điểm
    cinemas?.forEach(async (cinema) => {
      if (!cinema?.maps) {
        cinema.kc = 99999
      } else {
        const [latitude, longitude] = cinema?.maps?.split(',').map(parseFloat)
        cinema.kc = tinhKhoangCach(currentLocation.latitude, currentLocation.longitude, latitude || 0, longitude || 0)
      }
    })

		// Sắp xếp mảng theo khoảng cách tăng dần
		cinemas?.sort((a, b) => (a.kc || 0) - (b.kc || 0))

		return cinemas
	}

	// Hàm sắp xếp vị trí và hiển thị thông tin
	navigator.geolocation.getCurrentPosition(
		(position) => {
			const currentLocation = {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude
			}

			// Sắp xếp mảng địa điểm theo thứ tự gần nhất
			const sortedCinemas = sortCinemasByDistance(a, currentLocation)

			// In thông tin các địa điểm sau khi sắp xếp
			if (sortedCinemas?.length > 0) {
				setValue(sortedCinemas)
			} else {
				setValue(a)
			}
      setError && setError(0)
			return sortedCinemas
		},
		(error) => {
			// console.log(`Không thể lấy vị trí hiện tại: ${error.message}`)
			setValue(a)
      setError && setError(error?.code)
		},
		options
	)
}
