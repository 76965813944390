import Swiper from "swiper";
import { Grid, Navigation,Pagination, Autoplay } from "swiper/modules";

export function swiperConfig(element: string, customizeOption: any, typePagi: string) {
    const swiperSlider = document.querySelectorAll(element);
    if (swiperSlider) {
       swiperSlider.forEach((item) => {
          const swiper: any = item.querySelector(".swiper");
          const pagi:any = item.querySelector(".swiper-pagination");
          const next = item.querySelector(".swiper-next");
          const prev = item.querySelector(".swiper-prev");
          if (!typePagi) {
             typePagi = "bullets";
          }
          new Swiper(swiper, {
             watchSlidesProgress: true,
             modules: [Navigation, Pagination, Grid, Autoplay],
             pagination: {
                el: pagi,
                type: typePagi,
                clickable: true,
             },
             navigation: {
                nextEl: next,
                prevEl: prev,
             },
             fadeEffect: {
                crossFade: true,
             },
             ...customizeOption,
          });
        });
      }
    }
