/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import { _format } from '@/utils/format';
import { useEffect, useState } from 'react';
import { CountQuantity } from '../common';


export const FoodItem = ({ combo, setComboPick, comboPick }: any) => {
  const [countQuantity, setCountQuantity] = useState(0)
  const  handleCountPlus = () => {
    setCountQuantity(countQuantity + 1);
  }
  const  handleCountMinus = () => {
      if(countQuantity <= 0){
        return;
      }else{
        setCountQuantity(countQuantity - 1);
      }
  }
  useEffect(() => {
    if (countQuantity > 0) {
      const indexIsExist = comboPick?.findIndex((item: any) => item?.id == combo?.id)
      if (indexIsExist >= 0) {
        const newArr = comboPick?.filter((item: any) => item?.id !== combo?.id)
        setComboPick([...newArr, { ...combo, qty: countQuantity }])
      } else {
        setComboPick([...comboPick, { ...combo, qty: countQuantity }])
      }
    } else {
      const newArr = comboPick?.filter((item: any) => item?.id !== combo?.id)
      setComboPick([...newArr])
    }
  }, [countQuantity])
useEffect(() => {
  const quantity = comboPick?.find((item: any) => item?.id == combo?.id)?.qty
  setCountQuantity(quantity||0)
}, [comboPick])

  return (
    <div className="combo-item col col-4">
      <div className="food-box">
        <div className="img">
          <div className="image"> <img src={`${combo?.image}`}alt="" /></div>
        </div>
        <div className="content">
          <div className="content-top"><p className="name sub-title cursor-pointer">{combo?.name}</p>
            <div className="desc">
              <p>{combo?.description}</p>
            </div>
            <div className="price sub-title">
              <p>{_format.getVND(parseFloat(combo?.price))}</p>
            </div>
          </div>
          <div className="content-bottom">
            <CountQuantity quantity={countQuantity} handlePlus={handleCountPlus} handleMinus={handleCountMinus} />
          </div>
        </div>
      </div>
    </div>
  )
}