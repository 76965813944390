import $ from "jquery"

export function collapseInit(){
    const collapseBlockJS = document.querySelectorAll('.collapseBlockJS');
    if (collapseBlockJS) {
      collapseBlockJS.forEach(item => {
        const clpItems: any = item?.querySelectorAll('.collapseItem')
        const clpBodys: any = item?.querySelectorAll('.collapseBody')
        $(clpItems[0]).addClass('active');
        $(clpBodys[0]).slideDown();
      })
    }
}
export const collapseAction = (e: any) => {
  const head = e.target?.closest('.collapseHead');
  const item = e.target?.closest('.collapseItem');
  if (head) {
    const body = head?.closest('.collapseItem')?.querySelector('.collapseBody');
    const clBodys = head?.closest('.collapseBlockJS')?.querySelectorAll('.collapseBody');
    const clItems = head?.closest('.collapseBlockJS')?.querySelectorAll('.collapseItem');
    clItems.forEach((item: any) => {
      $(item).removeClass("active");
    })
    clBodys.forEach((bd: any) => {
      $(bd).slideUp();
    })
    if(body.style.display === 'none' || body.style.display === ''){
      $(body).slideDown()
      $(item).addClass("active");
    }else{
      $(body).slideUp()
      $(item).removeClass("active");
    }
  }
}

export const collapseActionRender = () => {
 document.onclick = (e:any) => {
    if(e.target.closest('.collapseBlockJS')){
      const head = e.target?.closest('.collapseHead');
      const item = e.target?.closest('.collapseItem');
      if (head) {
        const body = head?.closest('.collapseItem')?.querySelector('.collapseBody');
        const clBodys = head?.closest('.collapseBlockJS')?.querySelectorAll('.collapseBody');
        const clItems = head?.closest('.collapseBlockJS')?.querySelectorAll('.collapseItem');
        clItems.forEach((item: any) => {
          $(item).removeClass("active");
        })
        clBodys.forEach((bd: any) => {
          $(bd).slideUp();
        })
        if(body.style.display === 'none' || body.style.display === ''){
          $(body).slideDown()
          $(item).addClass("active");
        }else{
          $(body).slideUp()
          $(item).removeClass("active");
        }
      }
    }
 }

}

export function collapseInitActiveAll(){
  const collapseBlockJS = document.querySelectorAll('.collapseBlockJS');
  if (collapseBlockJS) {
    collapseBlockJS.forEach(item => {
      const clpItems: any = item?.querySelectorAll('.collapseItem')
      const clpBodys: any = item?.querySelectorAll('.collapseBody')
      clpItems.forEach((item:any)=>$(item).addClass('active'))
      clpBodys.forEach((item:any)=>$(item).slideDown())
    })
  }
}
export const collapseActionRenderByOne = () => {
  document.onclick = (e:any) => {
     if(e.target.closest('.collapseBlockJS')){
       const head = e.target?.closest('.collapseHead');
       const item = e.target?.closest('.collapseItem');
       if (head) {
         const body = head?.closest('.collapseItem')?.querySelector('.collapseBody');
         if(body.style.display === 'none' || body.style.display === ''){
           $(body).slideDown()
           $(item).addClass("active");
         }else{
           $(body).slideUp()
           $(item).removeClass("active");
         }
       }
     }
  }
 
 }