/* eslint-disable @next/next/no-img-element */

export const LoadScreen = () => {
  return (
    <div className="loader open">
      <div className="loader-logo">
        <img src="/assets/images/header-logo.png" alt="" />
      </div>
    </div>
  )
}