/* eslint-disable react-hooks/exhaustive-deps */
import { _format } from '@/utils/format';
import { useEffect, useState } from 'react';
import { PopupNoti } from '../common';
import useContentGlobal from '@/hooks/useContentGlobal';

type Props = {
  data: any,
  setQtyTicket:(data: any) => void
  qtyTicket: TShowTimePrice[]
  maxTicket: any
  isAdd: boolean
  setModalNoti: (val: boolean) => void
  scrollToSeatProps?: any
  isTheFirstBack?:boolean,
  setIsTheFirstBack:(val:boolean) => void
}


export const TypeTicket = (props: Props) => {
  const [quantity, setQuantity] = useState(0);
  const [isStudentTicket, setStudentTicket] = useState({isOpened: false, status: false})
  const { getValueByKey } = useContentGlobal();

  const handleToggleStudentTicket = (prompt?: string) => {
    if(prompt && prompt == "cancel") {
      setStudentTicket({isOpened: false, status: false});
      return false
    }
    if(isStudentTicket.isOpened){
      setStudentTicket(prev => ({...prev, status: false}));
      setQuantity(quantity + 1);
      props.scrollToSeatProps();
      return true
    }else{
      setStudentTicket({isOpened: true, status: true});
      return false
    }
  }
  const handlePlus = () => {
    props?.isTheFirstBack && props?.setIsTheFirstBack(false)
    if (!props?.isAdd) {
      if(!isStudentTicket.isOpened && props.data.ticket_name.includes("HSSV")){
        if(handleToggleStudentTicket() == false) return
      }
      setQuantity(quantity + 1);
      props.scrollToSeatProps();
    } else {
      props?.setModalNoti(true)
    }
  }
  const handleMinus = () => {
    props?.isTheFirstBack && props.setIsTheFirstBack(false)
    if(quantity <= 0){
      return;
    }else{
      const newQuantity = quantity - 1
      if(isStudentTicket.isOpened && props.data.ticket_name.includes("HSSV") && newQuantity <= 0){
        setStudentTicket({isOpened: false, status: false})
      }
      setQuantity(newQuantity);
      props.scrollToSeatProps();
    }
  }
  useEffect(() => { 
    const quantity = props?.qtyTicket?.filter((item) => item?.ticket_id == props?.data?.ticket_id && item?.seatstyle_id == props?.data?.seatstyle_id)?.[0]?.qty
    setQuantity(quantity ??0)
  }, [props?.qtyTicket])
  // Lấy list số lượng theo type showPrice
  useEffect(() => {
    if (quantity > 0) {
      const isExist = props?.qtyTicket?.find((item) => item?.ticket_id == props?.data?.ticket_id && item?.seatstyle_id == props?.data?.seatstyle_id)
      if (!isExist) {
        props?.setQtyTicket([...props?.qtyTicket, { ...props?.data, qty: quantity }])
      } else {
        const newArr = props?.qtyTicket?.map((item) => {
          if (item?.ticket_id == props?.data?.ticket_id && item?.seatstyle_id == props?.data?.seatstyle_id) {
            return {
              ...item,
              qty: quantity
            }
          } else {
            return item
          }
        })
        props?.setQtyTicket(newArr)
      }
    } else {
      const newArr = props?.qtyTicket?.filter((item) => item?.ticket_id !== props?.data?.ticket_id && item?.seatstyle_id !== props?.data?.seatstyle_id )
      !props?.isTheFirstBack && props?.setQtyTicket(newArr)
    }
    
  }, [quantity])

  useEffect(() => {
    if (!props?.qtyTicket || props?.qtyTicket?.length == 0) {
      setQuantity(0)
    }
  }, [props?.qtyTicket])
  
  return (
    <>
    <PopupNoti extraButtonCancle extraButtonFunction={()=>handleToggleStudentTicket("cancel")} isOpen={isStudentTicket.status} handleClose={handleToggleStudentTicket} content={getValueByKey("Ticket_Note_Student_Popup") ?? "Bạn đang mua hạng vé đặc biệt dành cho HSSV, U22 hoặc người cao tuổi. Vui lòng mang theo CCCD hoặc thẻ HSSV có dán ảnh để xác minh trước khi vào rạp. Nhân viên rạp có thể từ chối không cho bạn vào xem nếu không thực hiện đúng quy định này. Trân trọng cảm ơn"} />
    <div className="food-box">
      <div className="content">
        <div className="content-top"><p className="name sub-title cursor-pointer">{props?.data?.ticket_name}</p>
        <div className="desc">
            <p>{props?.data?.seatstyle_name}</p>
        </div>
        <div className="price sub-title">
            <p>{_format.getVND(parseFloat(props?.data?.price))}</p>
        </div>
      </div>
      <div className="content-bottom">
        <div className="count">
          <div className="count-btn count-minus" onClick={handleMinus}><i className="fas fa-minus icon"></i></div>
          <p className="count-number">{quantity}</p>
          <div className="count-btn count-plus" onClick={handlePlus}><i className="fas fa-plus icon"></i></div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}