/* eslint-disable @next/next/no-img-element */
import { toggleLanguage } from '@/helper'
import useContentGlobal from '@/hooks/useContentGlobal'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

type TProps = {
  mv: TMovie
  child?: any
  idServer?: string
  idArea?: string
  btnText?: string
  isLazy?: boolean
  isComingSoon?: boolean
}

export const MovieItemMobile: React.FC<TProps> = ({ mv, idServer, idArea, btnText, isLazy, isComingSoon }) => {
  const { getValueByKey } = useContentGlobal();

  const renderTextBtn = () => {
    if (btnText) return btnText;
    return getValueByKey("Buy_Tickets") ?? "Đặt vé"
  }

  return (
    <Link className='relative' href={`/movie/${mv?.id}${idServer && idArea ? `?id=${idArea}&id_sv=${idServer}` : ""}`}>
      <Image style={{ aspectRatio: 280 / 423 }} objectFit='cover' src={mv.image ?? ""} alt="" quality={40} width={280} height={423} sizes="(max-width: 768px) 135px, 280px"
        className="border-[0.1rem] border-solid border-[#808080] rounded-[0.4rem] overflow-hidden" loading={isLazy ? "lazy" : "eager"} />
      {!!isComingSoon && <div className='date-premiere mt-[1.2rem] text-center !text-[1.2rem] text-[#ffffffc7]'>{getValueByKey(["Movie_Detail", "Premiere"])}: {moment(new Date(mv?.release_date))?.format("DD/MM/YYYY")}</div>}
      <div className={`name text-center text-[1.2rem] ${!!isComingSoon ? 'mt-[1.4rem]' : 'mt-[2rem]'} mb-[0.8rem] font-semibold !line-clamp-2 inline-block min-h-[40px]`} aria-label={toggleLanguage(mv, "name")}>{toggleLanguage(mv, "name") || ""}</div>
      <div className="btn btn--pri mt-auto text-[1.2rem]">{renderTextBtn()}</div>

      <div className={`absolute left-[0.1rem] !top-[0.1rem] rounded-tl-[0.3rem] type-movie bg-[#f93] h-[2.2rem] p-[0.4rem] text-black uppercase ${mv?.formats_name_vn != "2D" ? "label-3d" : ""}`} style={{ fontFamily: '"Anton", sans-serif' }}>
        <span className="txt border-[0.16rem] border-black border-solid text-[0.6rem] h-[1.4rem] w-[1.4rem] flex items-center justify-center rounded-[0.4rem]">{toggleLanguage(mv, "formats_name") || ""}</span>
      </div>
      <div className="absolute left-[2.3rem] top-[0.1rem] text-[0.8em] age bg-[#f03] flex items-center justify-center uppercase px-[0.3rem] h-[2.2rem] w-fit" style={{ fontFamily: '"Anton", sans-serif' }}>
        {toggleLanguage(mv, "limitage") || ""}
      </div>
    </Link>
  )
}