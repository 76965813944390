/* eslint-disable @next/next/no-img-element */
import { Fragment, useEffect, useState } from 'react';
import { PopupNoti } from '../common';
// @ts-ignore
import Minimap from "react-minimap";
import 'react-minimap/dist/react-minimap.css';
import { LoadingCustom } from '../common/LoadingCustom';
import IndicatorScroll from './IndicatorScroll';
import useContentGlobal from '@/hooks/useContentGlobal';


export const MovieSeat = ({ listSeat, qtyTicket, setOrdersSeat, ordersSeat, cinemaInterest, isLoading, refElementRef, isExistOrderSeat }: any) => {
  const [noti, setNoti] = useState({ value: false, content: "", })
  const { getValueByKey } = useContentGlobal();
  let currentRow = 0

  const HanleMapShowHide = () => {
    const miniMap = document.querySelector(".minimap");
    if (miniMap?.classList.contains("show")) {
      miniMap?.classList.remove("show");
    } else {
      miniMap?.classList.add("show");
    }
  }
  const HandleMapTouchStart = () => {
    HanleMapShowHide();
  };

  const HandleMapTouchEnd = () => {
    HanleMapShowHide();
  };

  const handleAddWithTypeSeat = (type: string, _this: any, seat: any, rows: any) => {
    // Chọn ghế đôi
    let totalSeat = 0 // Tổng số lượng ghế có thể đặt
    qtyTicket?.filter((item: any) => item?.seatstyle_name == type)?.map((item: any) => {
      totalSeat += item?.qty
    })
    const listOrdersSeatCouple = ordersSeat?.filter(((item: any) => item?.seat_type == type))
    const indexSeat = rows?.findIndex((item: any) => item?.name == seat?.name)
    const isCheckLeft = ordersSeat?.find((val: any) => val?.name == rows[indexSeat - 1]?.name)
    const isCheckRight = ordersSeat?.find((val: any) => val?.name == rows[indexSeat + 1]?.name)
    const isCheckLeft2 = ordersSeat?.find((val: any) => val?.name == rows[indexSeat - 2]?.name)
    const isCheckRightt2 = ordersSeat?.find((val: any) => val?.name == rows[indexSeat + 2]?.name)

    // vừa thêm !isCheckRight !isCheckLeft ở cặp điều kiện đầu tiên

    const isLeft = (!rows[indexSeat - 1]?.issold && rows[indexSeat - 2]?.issold && !isCheckLeft && !isCheckRight) || (rows[indexSeat - 1]?.issold == false && !rows[indexSeat - 2] && !isCheckLeft) || (isCheckLeft2 && rows[indexSeat - 1]?.issold == false && !isCheckLeft) || (rows[indexSeat - 1]?.issold == false && rows[indexSeat - 2]?.seatindex == 0 && !isCheckLeft)
    const isRight = (!rows[indexSeat + 1]?.issold && rows[indexSeat + 2]?.issold && !isCheckRight && !isCheckLeft) || (rows[indexSeat + 1]?.issold == false && !rows[indexSeat + 2] && !isCheckRight) || (isCheckRightt2 && rows[indexSeat + 1]?.issold == false && !isCheckRight) || (rows[indexSeat + 1]?.issold == false && rows[indexSeat + 2]?.seatindex == 0 && !isCheckRight)

    const isDouble = ((rows[indexSeat - 1]?.issold == null || rows[indexSeat - 1]?.issold == true) && rows[indexSeat + 1]?.issold == false) || ((rows[indexSeat + 1]?.issold == null || rows[indexSeat + 1]?.issold == true) && rows[indexSeat - 1]?.issold == false)

    if (_this.classList.contains("choosing")) {
      if (type == "ĐƠN" && isCheckLeft && isCheckRight && isCheckLeft) {
        setNoti({ value: true, content: getValueByKey(["Message","Error","Select_Seat_1"]) ?? "Việc chọn ghế của bạn không được để trống 1 ghế ở bên trái, giữa hoặc bên phải trên cùng một hàng ghế mà bạn vừa chọn!" });
      } else {
        const newArr = ordersSeat?.filter((item: any) => item?.ticketid !== seat?.ticketid) || []

        setOrdersSeat(newArr)
      }
    } else {
      if (type == "ĐƠN" && (isLeft || isRight) && seat?.seatindex !== 1 && seat?.seatindex !== rows?.length && !isDouble) {
        setNoti({ value: true, content: getValueByKey(["Message","Error","Select_Seat_1"]) ?? "Việc chọn ghế của bạn không được để trống <span class='c-second'>1 ghế ở bên trái, giữa</span> hoặc <span class='c-second'>bên phải trên cùng</span> một hàng ghế mà bạn vừa chọn!" });
      } else {
        if (totalSeat == 0 && !_this.classList.contains("choosing")) { //không mua
          setNoti({ value: true, content: getValueByKey(["Message","Error","Empty_Seat"]) ?? "Bạn không có mua ghế loại này!" });
        } else {
          if (listOrdersSeatCouple?.length >= totalSeat) {
            setNoti({ value: true, content: "Bạn đã mua đủ ghế loại này!" });
          } else {
            setOrdersSeat([...ordersSeat, seat])
            sessionStorage.setItem('timerSeat', new Date().getTime().toString())
          }

        }
      }
    }
  }

  useEffect(() => {
    if (ordersSeat?.length > 0) {
      sessionStorage.setItem('timerSeat', (new Date().getTime() + 5* 60 * 1000).toString())
      window.dispatchEvent(new Event('timerSeat'));
    }
  }, [ordersSeat])

  useEffect(() => {
    !isExistOrderSeat && setOrdersSeat([])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTicket])

  const handleChooseSeat = (e: any, seat: any, rows: any) => {
    const _this = e.currentTarget;
    if(!seat.seat_type) return
    handleAddWithTypeSeat(seat.seat_type, _this, seat, rows)
    // if (seat.seat_type == "ĐÔI") {
    //   // Chọn ghế đôi
    //   handleAddWithTypeSeat("ĐÔI", _this, seat, rows)
    // }
    // if (seat.seat_type == "ĐƠN") {
    //   // Chọn ghế đôi
    //   handleAddWithTypeSeat("ĐƠN", _this, seat, rows)
    // }
    // if (seat.seat_type == "NẰM") {
    //   // Chọn GIƯỜNG
    //   handleAddWithTypeSeat("NẰM", _this, seat, rows)
    // }
  }
  useEffect(()=>{
    const listSeatVip:NodeListOf<HTMLElement>  = document.querySelectorAll(".seat-table table tr .seat-td.seat-vip-row-1")
    //listSeatVip2 - like the listSeatVip but containing empty seat
    const listSeatVip2:NodeListOf<HTMLElement>  = document.querySelectorAll(".seat-table table tr .seat-vip-row-1")
    // const lastRowVip:NodeListOf<HTMLElement>  = document.querySelectorAll(`.seat-table table tr .seat-td.seat-vip-row-${currentRow}`)
    // const lastRowVip2:NodeListOf<HTMLElement>  = document.querySelectorAll(`.seat-table table tr .seat-vip-row-${currentRow}`)
    listSeatVip && listSeatVip[0]?.classList.add("first-vip")
    listSeatVip && listSeatVip[listSeatVip.length - 1]?.classList.add("last-vip")
    // lastRowVip && lastRowVip[0]?.classList.add("first-vip")
    // lastRowVip && lastRowVip[lastRowVip.length - 1]?.classList.add("last-vip")
    const parentFirstVip = listSeatVip[0]?.parentElement?.querySelectorAll("td")
    if(parentFirstVip){
      const indexFirstVip = Array.from(parentFirstVip)?.findIndex(el => el.classList.contains("first-vip"));
      const indexLastVip = Array.from(parentFirstVip)?.findIndex(el => el.classList.contains("last-vip"));
      for(let i = 2; i <= currentRow; i++){
        const middleRowVip:HTMLElement | undefined | null  = document.querySelector(`.seat-table table tr .seat-vip-row-${i}`)?.closest("tr")
        const childMiddleRow = middleRowVip?.querySelectorAll("td")
        if(childMiddleRow){
           const countSeatCouple = Array.from(childMiddleRow).filter((el,index) => el.classList.contains("seat-couple") && index < indexFirstVip)
          const indexFirst = countSeatCouple.length > 0 ? indexFirstVip - countSeatCouple.length : indexFirstVip
          const indexLast = countSeatCouple.length > 0 ? indexLastVip - countSeatCouple.length : indexLastVip
          childMiddleRow[indexFirst]?.classList.add(i == currentRow ? "first-vip" : "seat-vip-row-middle-first")
          childMiddleRow[indexLast]?.classList.add(i == currentRow ? "last-vip" :"seat-vip-row-middle-last")
          i == currentRow && childMiddleRow.forEach((item, i)=>{
            if(i <= indexLast && i >= indexFirst){
              item.classList.contains(`seat-vip-row-${currentRow}`) && item.classList.add("seat-vip-row-last")
             if(!item.classList.contains("seat-td")){
               item.classList.add("seat-special-bot")
             }
            }
          })
        }
      }
    } 
    const indexFirstSeat = Array.from(listSeatVip2).findIndex(item => item.classList.contains("first-vip"));
    const indexLastSeat = Array.from(listSeatVip2).findIndex(item => item.classList.contains("last-vip"));
    listSeatVip2.forEach((item, i)=>{
      item.classList.add("seat-vip-row-1")
      if(!item.classList.contains("seat-td") && i < indexLastSeat && i > indexFirstSeat){
        item.classList.add("seat-special-top")
      }
    })
    // lastRowVip2.forEach((item, i)=>{
    //  item.classList.add("seat-vip-row-last")
    //   if(!item.classList.contains("seat-td") && i < indexLastSeat && i > indexFirstSeat){
    //     item.classList.add("seat-special-bot")
    //   }
    // })
  },[listSeat, currentRow])
  const handleClosePopupNoti = () => {
    setNoti({ value: false, content: noti?.content });
  }
  const handleGetClass = (maxColumn:number) =>{
    if(maxColumn <= 13) return "--sm" 
    if(maxColumn <= 16) return "--nor"
    if(maxColumn < 21 ) return "--full"
    return "--full --large"
  }
  if ((!listSeat || listSeat?.length == 0) || !cinemaInterest ) {
    return null
  }

  let maxColumn = listSeat?.[0]?.maxcolumn;
  let maxRow = listSeat?.length;

  let rowSeatCenter = Math.ceil(maxColumn * 0.4);
  let colSeatCenter = Math.ceil(maxRow * 0.4);


  if (maxColumn % 2 != 0 && rowSeatCenter % 2 == 0) {
    rowSeatCenter = rowSeatCenter - 1;
  }

  if (maxRow % 2 != 0 && colSeatCenter % 2 == 0) {
    colSeatCenter = colSeatCenter - 1;
  }
  const rowStart = Math.ceil((maxColumn - rowSeatCenter) / 2);
  const colStart = Math.ceil((maxRow - colSeatCenter) / 2);
  return (
    <Fragment>
      <PopupNoti isOpen={noti?.value} handleClose={handleClosePopupNoti} classCustom='--w7' contentTitle="LƯU Ý !" content={noti?.content} />
      <section className="sec-seat" >
        <div className="seat">
          <div className="container">
            <div className="seat-wr">
              <div className="seat-heading sec-heading" data-aos="fade-up">
                <h2 className="heading">{getValueByKey("select_Seats")} - {getValueByKey("Theater") ?? "Rạp"} {cinemaInterest?.room_name}</h2>
              </div>
              <IndicatorScroll>
                <div className={`seat-block relative ${handleGetClass(maxColumn)}`}>
                  {
                    isLoading && <LoadingCustom />
                  }
                  <div className="seat-screen" data-aos="fade-up"><img src="/assets/images/img-screen.png" alt="" />
                    <div className="txt">{getValueByKey("Screen")}</div>
                  </div>
                  <div className="seat-main" onTouchStart={HandleMapTouchStart} onTouchEnd={HandleMapTouchEnd} data-aos="fade-up">
                    <Minimap selector=".seat-td" width={130} keepAspectRatio={true}>
                      <div className="seat-table">
                        <table className='seat-table-inner' ref={refElementRef}>
                          {listSeat?.map((value: any, index: number) => {
                            const rowNAme = value.rowname;
                            const rowSeat = value.rowseats;
                            let isDifferentRow = false
                            return (
                              <tr key={index}>
                                <td className="seat-name-row">{rowNAme}</td>
                                {rowSeat.map((seat: any, i: number) => {
                                  const isChoosing = ordersSeat?.find((item: any) => item?.ticketid == seat?.ticketid)
                                  const isSeatVip = (i >= rowStart && i < (rowSeatCenter + rowStart)) && (index >= colStart && index < (colSeatCenter + colStart)) && cinemaInterest?.room_type_name_en == "Standard"
                                  if(isSeatVip){
                                    currentRow = !isDifferentRow ? currentRow + 1 : currentRow
                                    isDifferentRow = true
                                  }
                                  if (seat.seat_type == "ĐÔI" && seat.price == 0) return <Fragment key={i}></Fragment>
                                  return <Fragment key={i}>
                                    {seat.seat_type == "ĐÔI" ?
                                      (
                                        <td key={i} className="seat-td seat-couple">
                                          <div className={seat.issold ? "seat-wr booked" : `seat-wr ${isChoosing ? "choosing" : ""}`} onClick={(e) => handleChooseSeat(e, seat, rowSeat)}>
                                            <img src="/assets/images/seat-couple-w.svg" alt="" />
                                            <span className="seat-name">{seat.name}</span>
                                          </div>
                                        </td>
                                      )
                                      :
                                      seat.colindex == i + 1 && seat.isseat && !seat.isoff ?
                                        (

                                          <td key={seat.ticketid} className={isSeatVip ? `seat-td seat-vip seat-vip-row-${currentRow}` : "seat-td"}>
                                            <div className={seat.issold ? "seat-wr seat-single booked" : `seat-wr seat-single ${isChoosing ? "choosing" : ""}`} onClick={(e) => handleChooseSeat(e, seat, rowSeat)}>
                                              {
                                                isSeatVip ?
                                                  // <img src="/assets/images/seat-vip.svg" alt=""/>
                                                  <img src="/assets/images/seat.svg" alt="" />
                                                  :
                                                  // <img src="/assets/images/seat-single.svg" alt="" />
                                                  <img src="/assets/images/seat.svg" alt="" />
                                              }
                                              <span className="seat-name">{seat.name}</span>
                                            </div>
                                          </td>
                                        ) : (
                                          <td key={cinemaInterest?.showtime_id} className={(i >= rowStart && i < (rowSeatCenter + rowStart)) && (index >= colStart && index < (colSeatCenter + colStart)) ? `seat-vip seat-vip-row-${currentRow}` : ""} />
                                        )}
                                  </Fragment>
                                })
                                }
                              </tr>
                            )
                          })
                          }
                        </table>
                      </div>
                    </Minimap>
                  </div>
                </div>
              </IndicatorScroll>
              <ul className="seat-note">
                {cinemaInterest?.room_type_name_en == "Standard" || cinemaInterest?.room_type_name_vn == "Standard" ?
                  <>
                    <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Seat_Normally")}</span>
                    </li>
                    {listSeat?.find((item: any) => item.rowseats?.find((seat: any) => seat.seat_type == 'ĐÔI')) && (
                      <li className="note-it note-it-couple">
                        <div className="image">
                          <img src="/assets/images/seat-couple-w.svg" alt="" />
                        </div>
                        <span className="txt">{getValueByKey("Seat_Double")} (2 {getValueByKey("People") ?? "người"})</span>
                      </li>
                    )}
                    {/* <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat-vip.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Seat_VIP")}</span>
                    </li> */}
                  </> :
                  <>
                    <li className="note-it">
                      <div className="image"> <img src="/assets/images/seat.svg" alt="" />
                      </div><span className="txt">{getValueByKey("Bed")} (1 {getValueByKey("Person") ?? "người"})</span>
                    </li>
                  </>
                }

                {/* <li className="note-it">
                <div className="image"> <img src="/assets/images/seat-vip.svg" alt="" />
                </div><span className="txt">Ghế Vip</span>
              </li> */}
                <li className="note-it">
                  <div className="image">
                    {/* <img src="/assets/images/seat-single-selecting.svg" alt="" /> */}
                    <img src="/assets/images/seat.svg" alt="" className='seat-choosing'/>
                  </div><span className="txt">{getValueByKey("Seat_Selected")}</span>
                </li>
                <li className="note-it">
                  <div className="image"> <img src="/assets/images/seat.svg" alt=""  className='seat-disable'/>
                  </div><span className="txt">{getValueByKey("Seat_Booked")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}