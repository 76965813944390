/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import { convertDate, TDDate, toggleLanguage } from '@/helper';
import useContentGlobal from '@/hooks/useContentGlobal';
import { collapseActionRenderByOne, collapseInitActiveAll } from '@/utils/CollapseConfig';
import { SortCinema } from '@/utils/sortCinema';
import { swiperConfig } from '@/utils/SwiperConfig';
import { Select } from 'antd';
import clsx from "clsx";
import moment from 'moment';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import { LoadingCustom } from '../../common/LoadingCustom';


type TProps = {
  handleSellectDate: (time: TDDate) => void,
  theaterData: any,
  selectDate: (data: any) => any,
  showTime: any,
  handleClickArea: (id_area: string) => void,
  areaData: TArea[],
  cinemaInterest: TTimeInterest | undefined
  setCinemaInterest: (value: TTimeInterest) => void
  setTheaterData: (value: any) => void
  isLoading?: boolean
  listShowTime: any
  setShowPrice: (value: any) => void
}

export const MovieSetTime: React.FC<TProps> = ({
  handleSellectDate,
  theaterData,
  selectDate,
  showTime,
  handleClickArea,
  areaData,
  cinemaInterest,
  setCinemaInterest,
  isLoading,
  listShowTime,
  setShowPrice
}: any) => {
  const router = useRouter()
  const [listAreaSort, setListCinemaSort] = useState<any[]>([])
  const [defautData, setDefautData] = useState({
    value: listAreaSort?.[0]?.id,
    label: toggleLanguage(listAreaSort?.[0], "name"),
  })

  const { getValueByKey } = useContentGlobal();

  useEffect(() => {
    if (router.query?.id) {
      setListCinemaSort(areaData)
    } else {
      SortCinema(areaData, setListCinemaSort)
    }
  }, [router.query?.id])
  useEffect(() => {
    setDefautData({
      value: listAreaSort?.[0]?.id,
      label: toggleLanguage(listAreaSort?.[0], "name"),
    })
  }, [listAreaSort])

  useEffect(() => {
    collapseInitActiveAll()
    collapseActionRenderByOne();
  }, [theaterData])

  useEffect(() => {
    if (areaData?.length > 0) {
      if (router?.query?.id) {
        const itemArea = areaData?.find((item: any) => item?.id == router?.query?.id)
        setDefautData({
          value: itemArea?.id,
          label: toggleLanguage(itemArea, "name"),
        })
        handleClickArea(itemArea?.id)
      } else {
        setDefautData({
          value: areaData?.[0]?.id,
          label: toggleLanguage(areaData?.[0], "name"),
        })
        handleClickArea(areaData?.[0]?.id)
      }
    }
    swiperConfig(".shtime-slider", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
    }, "bullets");
    //check reload khi chọn khu vực
  }, [listAreaSort])
  // }, [areaData, router?.query])

  if (!areaData || areaData?.length == 0) {
    return null
  }

  if (!listShowTime?.length) {
    return <div className='mona-noti-custom'>
      <div className="container">
        <div className="box">
          <i className="fa-sharp fa-regular fa-film-slash"></i>
          <p> {getValueByKey("Showtime_Empty")}</p>
        </div>
      </div>
    </div>
  }

  return (
    <section className="sec-shtime">
      <div className="shtime">
        <div className="container">
          <div className="shtime-wr">
            <div className="shtime-heading" data-aos="fade-up">
              <h2 className="heading">{getValueByKey("Showtime")}</h2>
              <div className="shtime-slider time-list">
                <div className="swiper-container">
                  <div className="swiper rows">
                    <div className="swiper-wrapper">
                      {listShowTime?.sort((a: any, b: any) => new Date(convertDate(a?.date)?.date || 0)?.getTime() - new Date(convertDate(b?.date)?.date || 0)?.getTime())?.map((item: TDDate, index: number) => {
                        // const isCheck = listShowTime?.find((val: any) => val?.date == dayjs(item?.date).format("DD/MM/YYYY"))
                        const formatItem = convertDate(item?.date)
                        return <Fragment key={index}>
                          <div
                            key={index}
                            onClick={() => {
                              handleSellectDate(formatItem)
                              router.push({
                                query: {
                                  ...router?.query,
                                  show_time: '',
                                  date: ''
                                }
                              }, undefined, { scroll: false })
                            }
                            }
                            className={clsx(
                              selectDate?.date?.split(" ")[0] == formatItem?.date?.split(" ")[0] && "active",
                              "swiper-slide time-item col",
                            )}
                          >
                            <div className={"box-time"}>
                              <p className="date">{formatItem?.value}</p>
                              <p className="day">{toggleLanguage(formatItem, "label")}</p>
                            </div>
                          </div>
                        </Fragment>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {listAreaSort?.length > 0 ? (
              <>
                <div className="shtime-body" data-aos="fade-up">
                  <h2 className="heading">{getValueByKey("Cinema_List")}</h2>
                  <div className="sort">
                    <div className="select-location">
                      <img src="/assets/images/ic-branch-map.svg" alt='icon'/>
                      <Select
                        value={defautData}
                        className='area-theater'
                        onChange={(e, data: any) => {
                          router.query?.id_sv && router.query?.id_sv.length> 0 && router.push({
                            pathname: router.pathname,
                            query: {
                              ...router.query,
                              id: e.toString(),
                              id_sv: '',
                              show_time: '',
                              date: ''
                            }
                          }, undefined, { scroll: false })
                          handleClickArea(e)
                          setShowPrice([])
                          setDefautData({
                            value: data?.value,
                            label: data?.label,
                          })
                        }}
                        options={areaData?.map((item: any) => ({
                          value: item?.id,
                          label: toggleLanguage(item, "name"),
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="shtime-ft" data-aos="fade-up">
                  {isLoading ?
                    <LoadingCustom />
                    : <ul className="cinestar-list collapseBlockJS">
                      {theaterData?.map((item: any) => {
                        const listTime: any = showTime?.schedule?.find((itmDate: any) => itmDate?.date.includes(selectDate?.value))?.times?.filter((val: { theater_id: string }) => val?.theater_id == item?.id) || []
                        const Standard = listTime?.filter((type: any) => type?.room_type_name_en == "Standard")
                        const Deluxe = listTime?.filter((type: any) => type?.room_type_name_en == "Deluxe")
                        const date = showTime?.schedule?.find((itmDate: any) => itmDate?.date.includes(selectDate?.value))?.date
                        // const date = showTime?.schedule?.[0]?.date
                        const presentTime = new Date().getTime() / 1000
                        return <Fragment key={item?.id}>
                          <li key={item?.id} className="cinestar-item collapseItem" >
                            <div className="cinestar-heading collapseHead">
                              <h4 className="tittle">{toggleLanguage(item, "name")}</h4><i className="fa-solid fa-angle-down icon"></i>
                            </div>
                            <div className="cinestar-body collapseBody">
                              <p className="addr">{item?.address}</p>
                              <ul className="list-info">
                                {Standard?.length == 0 && Deluxe?.length == 0 &&
                                  <div className="none-time" >
                                    <img src="/assets/images/movie-updating.png" alt="" />
                                    <div className="tt">{getValueByKey("Showtime_Empty")}</div>
                                  </div>
                                }
                                {Standard?.length > 0 &&
                                  <li className="item-info">
                                    <div className="tt">{toggleLanguage(Standard[0], "room_type_name")}</div>
                                    <ul className="list-time">
                                      {Standard?.map((time: any) => {
                                        const movieTime = moment(`${date} ${time?.time}`, "DD/MM/YYYY HH:mm:ss").unix()
                                        const isLock = movieTime < presentTime
                                        return <Fragment key={time?.private_key}>
                                          <li
                                            onClick={() => {
                                              !isLock && setCinemaInterest({ ...time, ...item })
                                            }}
                                            className={clsx(cinemaInterest?.private_key == { ...time, }?.private_key && "active", isLock && "disable", "item-time")}
                                          >
                                            {time?.time}
                                          </li>
                                        </Fragment>
                                      })}
                                    </ul>
                                  </li>
                                }
                                {Deluxe?.length > 0 &&
                                  <li className="item-info">
                                    <div className="tt">{toggleLanguage(Deluxe[0], "room_type_name")}</div>
                                    <ul className="list-time">
                                      {Deluxe?.map((time: any) => {
                                        const movieTime = moment(`${date} ${time?.time}`, "DD/MM/YYYY HH:mm").unix()
                                        const isLock = movieTime < presentTime
                                        return <Fragment key={time?.private_key}>
                                          <li
                                            onClick={() => {
                                              !isLock && setCinemaInterest({ ...time, ...item })
                                            }}
                                            className={clsx(cinemaInterest?.private_key == time?.private_key && "active", isLock && "disable", "item-time")}
                                          >
                                            {time?.time}
                                          </li>
                                        </Fragment>
                                      })}
                                    </ul>
                                  </li>
                                }
                              </ul>
                            </div>
                          </li>
                        </Fragment>
                      })}
                    </ul>}

                </div>
              </>
            ) : <LoadingCustom classCustom='!relative !pb-[60px] !bg-[transparent] !pt-[20px]' />}


          </div>
        </div>
      </div>
    </section >
  )
}
