import React from 'react'

type Props = {
  classCustom?: string,
}

export const LoadingCustom = (props: Props) => {
  return (
    <div className={`lding ${props.classCustom}`}>
      <div className="lding-wr">
        <div className="lding-box">
          <div className="inner"></div>
        </div>
        <div className="lding-line"></div>
      </div>
    </div>
  )
}