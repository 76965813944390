import { homeApi } from '@/services'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const PopupPromotion: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false)
  const isClosed = typeof window !== 'undefined' && JSON.parse(sessionStorage.getItem('promotion') ?? '0')
  const router = useRouter()
  const { data: listPromotion } = useQuery({
    queryKey: ['listPopupPromotion'],
    queryFn: (): any => homeApi.getSlider('popup-homepage'),
    onSuccess: (data) => {
      if (data?.Slider?.items?.[0]?.Banner?.items.length > 0 && !(isClosed > 0) && router.asPath == '/') setShowPopup(true)
    }
  })
  useEffect(() => {
    if (router.asPath == '/' && !(isClosed > 0)) {
      setShowPopup(true)
    }
  }, [isClosed, router])
  const handleClosePopup = () => {
    setShowPopup(false)
    sessionStorage.setItem('promotion', JSON.stringify(new Date().valueOf()))
  }

  if (!listPromotion?.Slider?.items?.[0]?.Banner?.items.length) return null
  return (
    <div className={`popup popup-promotion ${showPopup ? 'open' : ''}`}>
      <div className="popup-overlay" onClick={handleClosePopup}></div>
      <div className="popup-main">
        <div className="popup-main-wrapper">
          <div className="popup-over">
            <motion.div
              initial={{ opacity: 0.8, scale: 0.85 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: .8,
                ease: [0, 0.71, 0.2, 1.01],
                delay: 0.1
              }}
            >
              <div className="popup-wrapper">
                <Link href={listPromotion?.Slider?.items?.[0]?.Banner?.items?.[0]?.link ?? '#'}>
                  <Image
                    src={listPromotion?.Slider?.items?.[0]?.Banner?.items?.[0]?.media}
                    alt="promotion"
                    fill
                    className="!static"
                  />
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="popup-close popupClose" onClick={handleClosePopup}>
          <i className="fas fa-times icon"></i>
        </div>
      </div>
    </div>
  )
}
export default PopupPromotion
