import useContentGlobal from '@/hooks/useContentGlobal'
import parse from 'html-react-parser'
type Props = {
  isOpen: boolean,
  handleClose: any,
  content?: string
  contentTitle?: string
  classCustom?: string
  extraButtonCancle?: boolean
  extraButtonFunction?: any
  handleOk?: Function
}

export const PopupNoti = (props: Props) => {
  const { getValueByKey } = useContentGlobal();
  return (
    <div className={`popup popup-noti ${props.classCustom ? props.classCustom : ""} ${props.isOpen ? "open" : ""}`}>
    <div className="popup-overlay" onClick={props.handleClose}>
    </div>
    <div className="popup-main">
      <div className="popup-main-wrapper">
        <div className="popup-over">
          <div className="popup-wrapper">
            <div className="popup-noti-wr">
                {
                  props?.contentTitle &&
                  <p className="popup-noti-title">{props?.contentTitle}</p>
                }
                {
                  props?.content &&
                  <p className="popup-noti-des">{parse(props?.content) }</p>
                }
                
                {props.extraButtonCancle ? <div className='popup-noti-extra'>
                  <div className="popup-noti-ctr">
                    <div className="btn btn--outline" onClick={props.extraButtonFunction ?? props.handleClose}>
                      <span className="txt">
                        {getValueByKey("Cancel") ?? "Hủy"}
                      </span>
                    </div>
                  </div>
                  <div className="popup-noti-ctr">
                    <div className="btn btn--outline" onClick={props?.handleOk ?? props.handleClose}>
                      <span className="txt">
                      {getValueByKey("Ok") ?? "Đồng ý"}
                      </span>
                    </div>
                  </div>
                </div>: <div className="popup-noti-ctr">
                  <div className="btn btn--outline" onClick={props.handleClose}>
                    <span className="txt">
                      OK
                    </span>
                  </div>
                </div>}
                
            </div>
          </div>
        </div>
      </div>
      <div className="popup-close popupClose">
        <i className="fas fa-times icon"></i>
      </div>
    </div>
  </div>
  )
}