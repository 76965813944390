import { FC, useEffect, useRef, useState } from "react"
import Image from 'next/image'

type IndicatorScrollProps = {
  children: React.ReactNode
}

const IndicatorScroll: FC<IndicatorScrollProps> = ({ children }) => {
  const [showImage, setShowImage] = useState<boolean>(false);
  const [countTimeRender, setCountTimeRender] = useState<number>(0);

  const containerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = (): any => {
      const rect = containerRef.current?.getBoundingClientRect();
      if(!rect) return
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isInView) {
        if (countTimeRender !== 0) return;

        setShowImage(true);
        setCountTimeRender(countTimeRender + 1)

        const timeout = setTimeout(() => {
          setShowImage(false);
        }, 2500);

        return () => clearTimeout(timeout);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [countTimeRender]);

  const renderImageIndicator = () => {
    if(!showImage) return null; 
    return <Image
    src={"/assets/gifs/Scroll-indicator-animation.gif"}
    alt="Scroll Indicator"
    className="img-indicator-scroll"
    width={50}
    height={50} />
  }

  return (
    <div className="seat-indicator-scroll" ref={containerRef}>
      {children}
      {renderImageIndicator()}
    </div>
  )
}

export default IndicatorScroll