import useContentGlobal from '@/hooks/useContentGlobal';
import { Fragment } from 'react';
import { FoodItem } from '../FoodItem';

export const MovieCombo = ({ comboData, setComboPick, comboPick }: any) => {
  const { getValueByKey } = useContentGlobal();

  if (!comboData || comboData?.length == 0) {
    return null
  }

  return (
    <section className="sec-dt-food">
      <div className="dt-food">
        <div className="container">
          <div className="dt-food-wr">
            <div className="dt-food-heading sec-heading" data-aos="fade-up">
              <h2 className="heading">{getValueByKey("Select_Concessions")}</h2>
            </div>
            <div className="dt-food-body">
              {comboData?.map((combo: any) => {
                return <Fragment key={combo?.id_group}>
                  <div key={combo?.id_group} className="dt-combo dt-item" data-aos="fade-up">
                    <div className="combo-tile">
                      <div className="title">{combo?.name_group}</div>
                    </div>
                    <div className="combo-content">
                      <div className="combo-list row">
                        {combo?.combo?.map((item: any) => {
                          return <FoodItem key={item?.id} combo={item} setComboPick={setComboPick} comboPick={comboPick} />
                        })}
                      </div>
                    </div>
                  </div>
                </Fragment>
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
