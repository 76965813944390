/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import { TypeTicket } from '../TypeTicket'
import { PopupNoti } from '../../common'
import { LoadingCustom } from '../../common/LoadingCustom'
import useContentGlobal from '@/hooks/useContentGlobal'

type TProps = {
  showPrice: TShowTimePrice[] | undefined
  setQtyTicket: (qty: TShowTimePrice[]) => void
  qtyTicket: TShowTimePrice[]
  maxTicket: any
  elementRef: any
  isLoading?: boolean
  scrollToSeat?: any
  cinemaInterest?:any
  isTheFirstBack?:boolean
  setIsTheFirstBack:(val: boolean) => void
}

export const MovieTicketPrice: React.FC<TProps> = ({ showPrice, setQtyTicket, qtyTicket, maxTicket, elementRef, isLoading, scrollToSeat, cinemaInterest, isTheFirstBack, setIsTheFirstBack }) => {
  const [modalNoti, setModalNoti] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const { getValueByKey } = useContentGlobal();

  const language = (typeof window !== "undefined") ? localStorage?.getItem('language') : 'vn';
  const messageNotion = language === "vn" 
    ? `Vui lòng chọn tối đa <span class="c-second">${maxTicket?.message}</span> ghế` 
    : `Please select up to <span class="c-second">${maxTicket?.message}</span> seats`;

 useEffect(() => {
   let totalQty = 0
    qtyTicket?.map((item: any) => {
     totalQty += item?.qty
   })
   if (totalQty > parseInt(maxTicket?.message)) {
     setModalNoti(true)
   } 
   if (totalQty == parseInt(maxTicket?.message)) {
     setIsAdd(true)
   } else {
     setIsAdd(false)
   }
 }, [qtyTicket])

  const handleClosePopupNoti = () => {
    setModalNoti(false);
  }


  if ((!showPrice || showPrice?.length == 0) || !cinemaInterest){
    return null
  }

  return (
    <section className="sec-ticket bill-fixed-start"  ref={elementRef}>
      <PopupNoti isOpen={modalNoti} handleClose={handleClosePopupNoti} content={messageNotion} />
      <div className="ticket">
        <div className="container">
          <div className="tickett-wr">
            <div className="ticket-heading sec-heading">
              <h2 className="heading">{getValueByKey("Select_Tickets")}</h2>
            </div>
            <div className="ticket-container relative">
              {
                isLoading && <LoadingCustom/>
              }
              <div className="ticket-ct">
                <div className="combo-content">
                  <div className="combo-list row" data-aos="fade-up">
                    {showPrice?.map((price) => {
                      let itemLenght = showPrice?.length;
                      return <Fragment key={price?.ticket_id}>
                        <div className={
                          `${itemLenght <= 2 ? ("combo-item col col-6") : itemLenght > 2 && itemLenght <= 3 ? ("combo-item col col-4") : ("combo-item col col-4")}`
                        } key={price?.ticket_id}>
                          <TypeTicket isTheFirstBack={isTheFirstBack} setIsTheFirstBack={setIsTheFirstBack} scrollToSeatProps={scrollToSeat}  data={price} setQtyTicket={setQtyTicket} qtyTicket={qtyTicket} maxTicket={maxTicket} isAdd={isAdd} setModalNoti={setModalNoti} />
                        </div>
                      </Fragment>
                    })}
                  </div>
                </div>
              </div>
              {/* <div className="ticket-ft">
                <p className="txt"><span className="dot">* </span>{getValueByKey("Note")}: </p><p>- {getValueByKey("Ticket_Note_Student")}</p><p>- {getValueByKey("Ticket_Note_Student_2")}</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
